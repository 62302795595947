<template>
  <div class="container">
    <h4 class="text-center my-4">Список преподавателей</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <DataTable :value="kpiCommission_form.teachers" showGridlines stripedRows
                 :globalFilterFields="['lastname','firstname']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск преподавателя"/>
            </span>
          </div>
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>

        <Column header="ФИО ППС">
          <template #body="{data}">



            <a @click.prevent="goToUser(data.user_id)" class="text-decoration-none" href="#">
              {{ data.lastname }} {{ data.firstname }}
            </a>
          </template>
        </Column>

        <Column field="total_score" header="Общее количество баллов">
          <template #body="{data}">
            <router-link :to="'/kpi-commission-teacher?user_id=' + data.user_id + '&lastname='+data.lastname+'&firstname='+data.firstname + '&month='+data.month+ '&year='+ data.year" target="_blank">
              {{data.total_score}}
            </router-link>
          </template>
        </Column>
        <Column field="index" header="Индекс достижения цели"></Column>
        <Column field="commission_score" header="Подтвержденные баллы">
          <template #body="{data}">
            <div v-if="data.commission_score == null">
              Балл не поставлен
            </div>
            <div v-else>
              {{data.commission_score}}
            </div>

          </template>
        </Column>



      </DataTable>






    </div>





  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";
export default {
  name: 'KpiCommission',
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'lastname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'firstname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
    }
  },
  computed: {
    ...mapState('kpiCommission', ['kpiCommission_form'])
  },

  methods: {

    ...mapActions('kpiCommission', ['GET_KPI_TEACHERS','POST_STUDENT_AGREEMENT']),
    ...mapMutations('userinfo', ['SET_USER_ID']),

    //...mapMutations('userinfo', ['SET_USER_ID']),

    goToUser(userId) {
      this.SET_USER_ID(userId)
      this.$router.push('/user')
    },

  },
  async mounted() {
    await this.GET_KPI_TEACHERS()

    this.loading = false
  },
}

</script>